import React from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import moment from 'moment';

import Logo from '../components/logo'

const DATA = require("../../redirect-data.json");
const BASE_URL = "https://www.sam-huang.info";
function jump(url) {
  window && (window.location.href = url);
}

import { graphql, useStaticQuery } from 'gatsby';

/****************************************/

const PINNED_ITEMS = [
  'RevtelIntro',
];

/****************************************/

const SEO = () => {
  const seo = {
    title: "部署網站至 SUI Walrus",
    image: BASE_URL + "/images/walrus/og.png",
    description: "部署網站至 SUI Walrus",
    url: BASE_URL + '/deploy-your-own-website-to-sui-walrus',
  };
  return (
    <Helmet
      title={seo.title}
      meta={[
        { name: "googlebot", content: "noindex" },
        { name: "robots", content: "noindex" },
        {
          name: `description`,
          content: seo.description,
        },
        {
          property: `image`,
          content: seo.image,
        },
        {
          property: `name`,
          content: seo.title,
        },
        {
          property: `og:title`,
          content: seo.title,
        },
        {
          property: `og:url`,
          content: seo.url,
        },
        {
          property: `og:site_name`,
          content: "Deploy Your Own Website to Sui Walrus",
        },
        {
          property: `og:image`,
          content: seo.image,
        },
        {
          property: `og:description`,
          content: seo.description,
        },
        {
          property: "og:url",
          content: seo.url,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:image`,
          content: seo.image,
        },
        {
          name: `twitter:creator`,
          content: "Sam Huang,黃奕翔",
        },
        {
          name: `twitter:title`,
          content: seo.title,
        },
        {
          name: `twitter:description`,
          content: seo.description,
        },
        {
          property: "keywords",
          content:
            "Sam Huang, Sam, sailplaneTW, 黃奕翔, 賣飛機的, 忻旅科技, Revtel, RevtelTech, 奕果雲端, Eculture, EcultureTech, React, React Native, Inigma, Tel25, Tel-U, 加密鏈, 軟體開發, 軟體顧問, 新創營運, web3, 區塊鏈",
        },
      ]}
    />
  );
};

const MainPage = () => {
  return (
    <Wrapper>
      <SEO />

      <a
        className="action"
        href="/"
        target="_blank"
        style={{
          cursor: "pointer",
          flexDirection: "flow",
          justifyContent: "center",
          alignItems: "center",
          textDecoration: "none",
          color: "black",
        }}
      >
        <a rel="noreferrer" src="/logo5.png" width={60 * 0.9} height={60 * 0.9} alt='Sam Huang 黃奕翔' style={{ borderRadius: '0%' }} />
      </a>

      <div className="content">
        <LangConfig />
        <h1 style={{ fontSize: '3em', textAlign: 'center' }}>Walrus Site 部署教學</h1>

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', width: '80%', maxWidth: 1024, minWidth: 240, }}>
          <h2 style={{ color: '#355F2E', fontSize: 36, alignSelf: 'flex-start' }}>[ 前言 ]</h2>
          <p>
            用 Web3 的概念來優化(甚至取代) 既有 Web2 的服務一直是區塊鏈發展的一個重要方向。
            過往也常常看到很多實驗性質的產品出現在市場上，但產品開發本來就不是一件簡單的事情，很多嘗試容易在激起一陣討論後就慢慢消失。
          </p>
          <p>前幾天在 X 上看到 Walrus (<a rel="noreferrer" style={{ display: 'inline-block', whiteSpace: 'nowrap' }} href='https://www.walrus.xyz/' target="_blank">https://www.walrus.xyz/</a>) 這個去中心化存儲服務能夠進行網站部署。試用了一下覺得蠻順暢的，在這裏簡單做個紀錄，目標是部署一個網站到 Walrus 上。</p><br />
          <p style={{ alignSelf: 'flex-start' }}>
            注意<br />
            - 本篇內容是用 mac 進行操作，如果您是其他環境可以參考官方文件(<a rel="noreferrer" style={{ display: 'inline-block' }} href='https://docs.walrus.site/walrus-sites/intro.html' target='_blank'>https://docs.walrus.site/walrus-sites/intro.html</a>)做修改。但觀念及步驟基本上是一樣的<br />
            - 目前 (2024/12/01) Walrus Site 仍然是在 testnet 上，未來如果正式推出至 mainnet 時，本篇內容可能無法使用</p>

          <img rel="noreferrer" onClick={() => window.open('https://sam-huang-website.walrus.site/', '_blank')} src='/images/walrus/1.png' style={{ width: '80%', alignSelf: 'center', cursor: 'pointer' }} />
          <a rel="noreferrer" href='https://sam-huang-website.walrus.site/' target='_blank' style={{ alignSelf: 'center' }}>https://sam-huang-website.walrus.site/</a>





          <h2 style={{ color: '#355F2E', fontSize: 36, alignSelf: 'flex-start' }}>[ 部署網站至 Walrus ]</h2>
          <h3 style={{ alignSelf: 'flex-start', fontSize: 24, marginTop: -10 }}>1. 環境設置：目的是讓您的電腦具有將網站部署到 walrus 上的能力</h3>
          <h4 style={{ marginTop: -10 }}>1.1 安裝 Rust 套件套件管理程式 Cargo</h4>
          <div style={{ backgroundColor: '#F1F0E8', width: '100%', padding: 10, marginTop: -10 }}>
            brew install rustup<br />
            rustup-init
          </div>
          <h4 style={{}}>1.2 安裝 sui cli</h4>
          <div style={{ backgroundColor: '#F1F0E8', width: '100%', padding: 10, marginTop: -10 }}>
            brew install sui
          </div>
          <h4 style={{}}>1.3 將環境切換到 testnet</h4>
          <div style={{ backgroundColor: '#F1F0E8', width: '100%', padding: 10, marginTop: -10 }}>
            sui client new-env --alias testnet --rpc https://fullnode.testnet.sui.io:443<br />
            sui client switch --env testnet
          </div>
          <h4 style={{}}>1.4 安裝 walrus</h4>
          <p rel="noreferrer" style={{ marginTop: -10 }}>這裏使用官方預先編譯好的 binary <a rel="noreferrer" style={{ display: 'inline-block' }} href='https://mystenlabs.github.io/walrus-docs/usage/setup.html' target='_blank'>https://mystenlabs.github.io/walrus-docs/usage/setup.html</a></p>
          <img rel="noreferrer" src='/images/walrus/2.png' style={{ width: '80%', alignSelf: 'center' }} />
          下載後將檔案改名成 walrus 並加入到 $PATH 中
          <h4 style={{}}>1.5 取得 testnet 上的 sui</h4>
          <div style={{ backgroundColor: '#F1F0E8', width: '100%', padding: 10, marginTop: -10 }}>
            sui client faucet
          </div>
          當然您也可以將帳戶切換成您常用的 sui wallet (透過 sui key import / sui client switch 等指令)
          <br />
          <br />

          <h3 style={{ alignSelf: 'flex-start', fontSize: 24, }}>2. 網站部署：目的是將指定的網站部署到到 Walrus 上</h3>
          <h4 style={{ marginTop: -10 }}>2.1 下載官方提供的 walrus-sites 並安裝</h4>
          <div style={{ backgroundColor: '#F1F0E8', width: '100%', padding: 10, marginTop: -10 }}>
            git clone https://github.com/MystenLabs/walrus-sites.git<br />

            cd walrus-sites<br />

            cargo build --release
          </div>
          <h4 style={{}}>2.2 部署 walrus-sites 範例程式碼中的貪食蛇網站</h4>
          <div style={{ backgroundColor: '#F1F0E8', width: '100%', padding: 10, marginTop: -10 }}>
            ./target/release/site-builder publish ./examples/snake --epochs 100
          </div>
          <h4 style={{}}>2.3 取得部署後網站網址</h4>
          <img rel="noreferrer" src='/images/walrus/3.png' style={{ width: '80%', alignSelf: 'center' }} />
          <p>圖中紅色的區域就是部署上去的網址 <a rel="noreferrer" href='https://2wrfk752zp9l8x1csitemjl0tom9duh25weja8eoqu5puapb6f.walrus.site/' target='_blank'>https://2wrfk752zp9l8x1csitemjl0tom9duh25weja8eoqu5puapb6f.walrus.site/</a>。您可以在瀏覽器試試看是否能看到網站！</p>
          而圖中的 object ID 0x74c1ede4c194f938674b244c060e70130d75e876b00dba316bbb470b637756d7 則是您這個網站在 SUI 上的資源 ID
          <img rel="noreferrer" src='/images/walrus/4.png' style={{ width: '80%', alignSelf: 'center' }} />

          <br />
          <br />
          <h3 style={{ alignSelf: 'flex-start', fontSize: 24, }}>3. 子網域名稱設定：目的是使網站的網址具有閱讀性</h3>
          <p style={{ marginTop: -10 }}> <a rel="noreferrer" href='https://2wrfk752zp9l8x1csitemjl0tom9duh25weja8eoqu5puapb6f.walrus.site/' target='_blank'>https://2wrfk752zp9l8x1csitemjl0tom9duh25weja8eoqu5puapb6f.walrus.site/</a> 是個不易閱讀的網址，我們可以透過 sui name service 賦予其有意義的名稱。</p>
          <h4 style={{}}>3.1 購買一個 SuiNS name：https://testnet.suins.io/</h4>
          <img rel="noreferrer" onClick={() => window.open('https://testnet.suins.io/', '_blank')} src='/images/walrus/5.png' style={{ width: '80%', alignSelf: 'center', cursor: 'pointer' }} />
          <a rel="noreferrer" href='https://testnet.suins.io/' target='_blank' style={{ alignSelf: 'center' }}>https://testnet.suins.io/</a>


          <h4 style={{}}>3.2 將已購買的 SuiNS name 連結到網站的 Object ID</h4>
          <img rel="noreferrer" src='/images/walrus/6.png' style={{ width: '80%', alignSelf: 'center', cursor: 'pointer' }} />
          <img rel="noreferrer" src='/images/walrus/7.png' style={{ width: '80%', alignSelf: 'center', cursor: 'pointer' }} />
          <p>然後您就可以用購買的 <a href='https://my-walrus-test.walrus.site/' rel="noreferrer" target="_blank">https://my-walrus-test.walrus.site/</a> 訪問剛剛部署的網站了！</p>









          <br />
          <br />
          <h2 style={{ color: '#355F2E', fontSize: 36, alignSelf: 'flex-start' }}>[ 結語 ]</h2>
          <p>
            其實 Walrus 託管網站的服務也上線好一陣子了，現在看到實在有點後知後覺。 <br /><br />
            好比這幾年 AI 變成不解釋技術細節的行銷用語，仿佛只要套上這個殼未來就一切光明 — 一樣的情況這幾年在區塊鏈發展上也屢見不鮮。作為一種新型且自帶經濟屬性的新網路基礎設施，區塊鏈帶給開發者跟用戶很多想像需要更多像 Walrus 這樣的服務。
          </p>

        </div>
      </div>
      <a
        className="action"
        href="/"
        target="_blank"
        style={{
          cursor: "pointer",
          flexDirection: "flow",
          justifyContent: "center",
          alignItems: "center",
          textDecoration: "none",
          color: "black", padding: 10
        }}
      >
        <img src='/logo6.png' width='120' />

      </a>
    </Wrapper>
  );
};

const LangConfig = () => {
  return (
    <div style={{ display: 'flex', padding: 5, backgroundColor: '#7BD3EA', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
      <a style={{ textDecoration: 'none', color: 'white', fontWeight: '600', fontSize: 24, width: 48, height: 48, borderRadius: 5, backgroundColor: '#0A3981', marginRight: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }} href='/deploy-your-own-website-to-sui-walrus/'>中</a>
      <a style={{ textDecoration: 'none', color: 'white', fontWeight: '600', fontSize: 24, width: 48, height: 48, borderRadius: 5, backgroundColor: '#D4EBF8', display: 'flex', justifyContent: 'center', alignItems: 'center' }} href='/deploy-your-own-website-to-sui-walrus_en/' > EN</a>
    </div >
  )
}


const Wrapper = styled.div`
    min-height: 98vh;


    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > .action {
        display: flex;
        flex-direction: row;
        //padding: 15px;
        width:100%;
        height:64px;
        background-color: #EEEEEE;
    }

    & > .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex:1;
        width:100%;
        padding-top:50px;
        padding-bottom:50px;
        //background-color: #FEFCF3;
    }
`;

export default MainPage;
